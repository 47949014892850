.AboutPage {
  background-color: var(--secondary-color);
  color: #fff;
}

.About {
  padding: 2em;
}
.About b {
  color: var(--primary-color);
}
.About .theDivide {
  display: flex;
}
.About .theDivide .first {
  width: 50%;
  margin: 1em;
}
.About .theDivide .first p {
  margin: 1em;
}
.About .theDivide .second {
  width: 50%;
  margin: 1em;
}
.About .theDivide .second svg{
  stroke: var(--primary-color);
}
.About .theDivide .second ul {
  list-style: none;
}
.About .theDivide .second ul li {
  display: flex;
  align-items: center;
  margin: 1em;
}
.About .theDivide .second ul li p {
  margin: 0 1em;
  width: 100%;
}
.About .theDivide .second ul li svg {
  width: 5%;
}
.About .theDivide .second .imgs {
  width: 100%;
  height: 30%;
  margin: 1em;
  text-align: center;
}
.About .theDivide .second .imgs img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}

.nhbrc {
  background-color: var(--primary-color);
  padding: 1em;
  text-align: center;
}
.nhbrc p {
  margin: 1em 0;
}
.nhbrc svg {
  width: 10%;
  fill: #fff;
}

@media screen and (max-width: 900px) {
  .About .theDivide {
    flex-direction: column;
  }
  .About .theDivide .first {
    width: 100%;
  }
  .About .theDivide .second {
    width: 100%;
  }
  .About .theDivide .second .imgs {
    margin: 0 0;
  }
  .About .theDivide .second .imgs img {
    width: 90%;
  }
  .nhbrc svg {
    width: 20%;
  }
}
@media screen and (max-width: 700px) {
  .AboutPage p {
    font-size: 1em;
  }
}
@media screen and (max-width: 400px) {
  .About {
    padding: 1em;
  }
  .About .first,
  .About .second {
    margin: 0.5em;
  }
  .nhbrc {
    padding: 0.5em;
  }
  .nhbrc svg {
    width: 30%;
  }
}