.Services {
  padding: 2em;
}
.Services b {
  color: var(--secondary-color);
}
.Services ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
}
.Services ul li {
  margin: 1em;
  cursor: pointer;
}
.Services ul li .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: var(--secondary-color);
  padding: 0.5em;
  border-radius: 15px;
}
.Services ul li .container .text {
  display: flex;
  align-items: center;
}
.Services ul li .container .text h3 {
  margin: 0 0.5em;
}
.Services ul li .container svg {
  fill: #fff;
  width: 10%;
}
.Services ul li p {
  display: none;
  margin: 1em;
  box-shadow: 0 4px 30px 5px rgba(33, 31, 32, 0.1882352941);
  padding: 1em;
  border-radius: 15px;
}

@media screen and (max-width: 1300px) {
  .Services ul {
    grid-template-columns: repeat(3, 1fr);
  }
  .Services ul li .container svg {
    width: 20%;
  }
}
@media screen and (max-width: 900px) {
  .Services ul {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .Services ul {
    grid-template-columns: repeat(1, 1fr);
  }
  .Services ul li {
    margin: 0.5em 0;
  }
}
@media screen and (max-width: 400px) {
  .Services {
    padding: 1em;
  }
}