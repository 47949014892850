.Testimonials {
  margin: 2em;
}
.Testimonials h2 {
  text-align: center;
  color: var(--secondary-color);
}
.Testimonials .container {
  overflow-x: auto; /* Enable horizontal scrolling */
  display: flex;
  align-items: center;
  scroll-snap-type: x mandatory; /* Enable mandatory snapping on the x-axis */
  padding: 1em;
  overflow-y: hidden;
}
.Testimonials .text {
  margin: 0 1em;
  padding: 2em;
  min-width: 80%;
  height: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 30px 5px rgba(33, 31, 32, 0.1882352941);
  border-radius: 10px;
  scroll-snap-align: end;
}
.Testimonials .text svg {
  fill: var(--secondary-color);
  width: 10%;
}
.Testimonials .text h3 {
  text-align: center;
  width: 100%;
  margin: 1em 0;
  font-weight: lighter;
}
.Testimonials .text p {
  color: var(--default-color);
}
.Testimonials .text svg:nth-of-type(2) {
  transform: rotate(180deg);
  align-self: flex-end;
}