.Contact {
  background-color: var(--secondary-color);
  padding: 1em;
}
.Contact h2 {
  text-align: center;
  color: #fff;
  margin: 1em 0;
}
.Contact .container {
  display: flex;
  justify-content: space-between;
}
.Contact .container .first {
  padding: 1em;
  width: 50%;
}
.Contact .container .first iframe {
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 50vh;
  outline: none;
}
.Contact .container .first button {
  width: 100%;
  padding: 1em 0;
  border-radius: 10px;
  margin: 1em 0;
  border: none;
  cursor: pointer;
}
.Contact .container .first button a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}
.Contact .container .first button svg {
  width: 5%;
  margin: 0 0.5em;
  fill: #fff;
}
.Contact .container .first button:nth-of-type(1) {
  background-color: #04d031;
}
.Contact .container .first button:nth-of-type(2) {
  background-color: var(--default-color);
}
.Contact .container form {
  padding: 1em;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Contact .container form h3 {
  text-align: center;
  font-weight: lighter;
  margin: 1em 0;
  color: #fff
}
.Contact .container form input,
.Contact .container form textarea {
  width: 80%;
  margin: 1em;
  padding: 1em;
  background: none;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  outline: none;
}
.Contact .container form button {
  width: 60%;
  margin: 1em;
  padding: 1em;
  background: none;
  border-radius: 50px;
  background-color: var(--default-color);
  border: none;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .Contact .container {
    flex-direction: column;
  }
  .Contact .container .first,
  .Contact .container form {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .Contact .container .first iframe {
    height: 20vh;
  }
}