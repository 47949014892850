.Home {
   height: 100vh;
   display: flex;
   padding: 2em;
   align-items: center;
}
.Home svg {
   .cls-1 {
      fill: #fff;
      filter: url(#drop-shadow-2);
   }

   .cls-1,
   .cls-2 {
      stroke-width: 0px;
   }

   .cls-2 {
      fill: none;
   }
   .cls-4 {
      clip-path: url(#clippath);
   }
}
.Home .text {
   width: 50%;
   margin: 2em;
}
.Home .text h1 {
   color: var(--default-color);
}
.Home .text h1:nth-of-type(2) {
   margin: 0 0 1em 0;
}
.Home .text b {
   color: var(--secondary-color);
}
.Home .text .btn {
   width: 100%;
   display: flex;
   justify-content: center;
}
.Home .text .btn button {
   width: 50%;
   background-color: var(--secondary-color);
   border: none;
   border-radius: 50px;
   padding: 1em 2em;
   cursor: pointer;
}
.Home .text .btn button p {
   color: #fff;
}
.Home .text .btn button a {
   text-decoration: none;
   color: #fff;
}
.Home .imgs {
   width: 50%;
}
.Home .imgs img {
   width: 100%;
}

@media screen and (max-width: 1300px) {
   .Home .text h1 {
      font-size: 3em;
   }
}
@media screen and (max-width: 1000px) {
   .Home {
      flex-direction: column;
      height: 100%;
   }
   .Home .text {
      width: 80%;
      margin: 2em 0;
   }
   .Home .text h1 {
      font-size: 4em;
   }
   .Home .imgs {
      width: 90%;
   }
}
@media screen and (max-width: 700px) {
   .Home .text h1 {
      font-size: 2.5em;
   }
}
@media screen and (max-width: 500px) {
   .Home .text {
      width: 100%;
      margin: 2em 0;
   }
   .Home .text h1 {
      font-size: 2em;
   }
   .Home .imgs {
      width: 100%;
   }
}
@media screen and (max-width: 350px) {
   .Home .text .btn button {
      width: 70%;
      padding: 0.5em 2em;
   }
}
