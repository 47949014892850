.Header {
  position: sticky;
  z-index: 2;
  top: 0.5em;
  left: 0;
  display: flex;
  margin: 0.5em;
  padding: 1em;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 30px 5px rgba(33, 31, 32, 0.1882352941);
  min-height: 100%;
  background-color: #fff;
}
.Header svg{
  stroke: var(--secondary-color);
}
.Header .menu ul {
  list-style: none;
  display: flex;
}
.Header .menu ul a {
  text-decoration: none;
}
.Header .menu ul li {
  margin: 0 2em;
  cursor: pointer;
}
.Header .menu ul li h2 {
  font-size: 1.5em;
  color: var(--default-color);
}
.Header .phone {
  background-color: var(--secondary-color);
  padding: 1em;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.Header .phone p {
  color: #fff;
}
.Header .phone a {
  color: #fff;
  text-decoration: none;
}

.frontLogo {
  width: 6%;
  cursor: pointer;
  justify-self: center;
}

.burgerIcon {
  display: none;
  width: 15%;
  cursor: pointer;
}

.sideMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 90%; /* Adjust as needed */
  background-color: #fff;
  box-shadow: 0 4px 30px 5px rgba(33, 31, 32, 0.1882352941);
  padding: 1em;
}
.sideMenu .imgs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
}
.sideMenu .imgs .logo {
  width: 15%;
  cursor: pointer;
}
.sideMenu .imgs .close {
  width: 5%;
  cursor: pointer;
}
.sideMenu hr {
  border: none;
  border-bottom: solid 2px var(--secondary-color);
}
.sideMenu .menu2 {
  margin: 5em 2em;
}
.sideMenu .menu2 ul {
  list-style: none;
}
.sideMenu .menu2 ul li {
  color: var(--secondary-color);
  margin: 2em 0;
  cursor: pointer;
}
.sideMenu .menu2 ul li a {
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  .Header .menu ul li h2 {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 1000px) {
  .Header .menu ul li h2 {
    font-size: 0.7em;
  }
}
@media screen and (max-width: 900px) {
  .Header .menu {
    display: none;
  }
  .Header .phone {
    padding: 0.5em 1em;
  }
  .frontLogo {
    width: 15%;
    cursor: pointer;
  }
  .burgerIcon {
    display: inline-block;
  }
}
@media screen and (max-width: 500px) {
  .Header .frontLogo {
    width: 20%;
    cursor: pointer;
    margin: 0 0 0 1.5em;
  }
  .Header .phone {
    font-size: 0.7em;
  }
  .Header .burgerIcon {
    width: 15%;
  }
}
@media screen and (max-width: 350px) {
  .Header .phone {
    font-size: 0.5em;
  }
  .sideMenu .menu2 {
    font-size: 0.6em;
  }
}